// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  buildCommit: "dev",
  buildTag: "",
  clientId: "BVE3qUuqOCRLHKl1MAJW0PhEYoAa",
  ssobaseurl: "https://auth.cnfpt.pcc.keyconsulting.fr/",
  issuer: "https://auth.cnfpt.pcc.keyconsulting.fr/oauth2/token",
  tokenDomain: [".*\\.keyconsulting\\.fr", "localhost"],
  reCAPTCHAKey: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",
  drupalUri: "",
  registrationUrl:
    "https://auth.cnfpt.pcc.keyconsulting.fr/registration?sp=annuaire-emploi-direction-dev&spPage=registration",
  logoutUrl:
    "https://auth.cnfpt.pcc.keyconsulting.fr/logout?sp=annuaire-emploi-direction-dev&spPage=home",
  accountUrl:
    "https://auth.cnfpt.pcc.keyconsulting.fr/profil?sp=annuaire-emploi-direction-dev&spPage=account",
  // drupalUri: 'https://drupal.interne.swarm.nantes.keyconsulting.fr',
};
